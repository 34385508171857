.headlines {
  background: $brand url('/assets/images/headline-background.png') no-repeat center;
  background-size: cover;
  padding: 1rem 0;
}

.headlines__ctas {
  padding: 4rem 0;
}

.row--sponsors {
  margin-bottom: 4rem;
  margin-top: 4rem;
}
